define("discourse/plugins/discourse-calendar/discourse/templates/connectors/user-custom-preferences/region", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n
        "discourse_calendar.region.title"
      }}</label>
    <div class="controls">
      <RegionInput
        @value={{this.model.custom_fields.holidays-region}}
        @allowNoneRegion={{true}}
        @onChange={{action "onChange"}}
      />
    </div>
    <DButton
      @icon="globe"
      @label="discourse_calendar.region.use_current_region"
      @action={{action "useCurrentRegion"}}
    />
  </div>
  */
  {
    "id": "uxbDgGvA",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"discourse_calendar.region.title\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@value\",\"@allowNoneRegion\",\"@onChange\"],[[30,0,[\"model\",\"custom_fields\",\"holidays-region\"]],true,[28,[37,4],[[30,0],\"onChange\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,5],null,[[\"@icon\",\"@label\",\"@action\"],[\"globe\",\"discourse_calendar.region.use_current_region\",[28,[37,4],[[30,0],\"useCurrentRegion\"],null]]],null],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"region-input\",\"action\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/connectors/user-custom-preferences/region.hbs",
    "isStrictMode": false
  });
});