define("discourse/plugins/discourse-calendar/discourse/templates/discourse-post-event-upcoming-events-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="discourse-post-event-upcoming-events">
    <UpcomingEventsCalendar @events={{this.model}} />
  </div>
  */
  {
    "id": "sNuG7G0z",
    "block": "[[[10,0],[14,0,\"discourse-post-event-upcoming-events\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@events\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"div\",\"upcoming-events-calendar\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/discourse-post-event-upcoming-events-index.hbs",
    "isStrictMode": false
  });
});