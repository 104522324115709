define("discourse/plugins/discourse-calendar/discourse/templates/admin-plugins-calendar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>
    {{i18n "discourse_calendar.holidays.header_title"}}
  </h3>
  
  <RegionInput
    @value={{this.selectedRegion}}
    @onChange={{action "getHolidays"}}
  />
  
  <p class="desc">
    {{i18n "discourse_calendar.holidays.pick_region_description"}}
    <br /><br />
    {{i18n "discourse_calendar.holidays.disabled_holidays_description"}}
  </p>
  
  <ConditionalLoadingSpinner @condition={{this.loading}} />
  
  {{#if this.model.holidays}}
    <AdminHolidaysList
      @holidays={{this.model.holidays}}
      @region_code={{this.selectedRegion}}
    />
  {{/if}}
  */
  {
    "id": "K7zHArtP",
    "block": "[[[10,\"h3\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"discourse_calendar.holidays.header_title\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,0,[\"selectedRegion\"]],[28,[37,3],[[30,0],\"getHolidays\"],null]]],null],[1,\"\\n\\n\"],[10,2],[14,0,\"desc\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"discourse_calendar.holidays.pick_region_description\"],null]],[1,\"\\n  \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n  \"],[1,[28,[35,1],[\"discourse_calendar.holidays.disabled_holidays_description\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"holidays\"]],[[[1,\"  \"],[8,[39,8],null,[[\"@holidays\",\"@region_code\"],[[30,0,[\"model\",\"holidays\"]],[30,0,[\"selectedRegion\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"h3\",\"i18n\",\"region-input\",\"action\",\"p\",\"br\",\"conditional-loading-spinner\",\"if\",\"admin-holidays-list\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/admin-plugins-calendar.hbs",
    "isStrictMode": false
  });
});