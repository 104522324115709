define("discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-reminder", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventReminder extends _rest.default {
    init() {
      super.init(...arguments);
      this.__type = "discourse-post-event-reminder";
    }
  }
  _exports.default = DiscoursePostEventReminder;
});