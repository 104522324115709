define("discourse/plugins/discourse-calendar/discourse/components/admin-holidays-list-item", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _object, _component2, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>{{this.holiday.date}}</td>
  <td>{{this.holiday.name}}</td>
  <td>
    {{#if this.isHolidayDisabled}}
      <DButton
        @action={{action "enableHoliday" this.holiday this.region_code}}
        @label="discourse_calendar.enable_holiday"
      />
    {{else}}
      <DButton
        @action={{action "disableHoliday" this.holiday this.region_code}}
        @label="discourse_calendar.disable_holiday"
      />
    {{/if}}
  </td>
  */
  {
    "id": "QyaZfy3+",
    "block": "[[[10,\"td\"],[12],[1,[30,0,[\"holiday\",\"date\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,[30,0,[\"holiday\",\"name\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"isHolidayDisabled\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@action\",\"@label\"],[[28,[37,3],[[30,0],\"enableHoliday\",[30,0,[\"holiday\"]],[30,0,[\"region_code\"]]],null],\"discourse_calendar.enable_holiday\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@action\",\"@label\"],[[28,[37,3],[[30,0],\"disableHoliday\",[30,0,[\"holiday\"]],[30,0,[\"region_code\"]]],null],\"discourse_calendar.disable_holiday\"]],null],[1,\"\\n\"]],[]]],[13]],[],false,[\"td\",\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/admin-holidays-list-item.hbs",
    "isStrictMode": false
  });
  const AdminHolidaysListItem = dt7948.c(class AdminHolidaysListItem extends _component.default {
    loading = false;
    isHolidayDisabled = false;
    disableHoliday(holiday, region_code) {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      return (0, _ajax.ajax)({
        url: `/admin/discourse-calendar/holidays/disable`,
        type: "POST",
        data: {
          disabled_holiday: {
            holiday_name: holiday.name,
            region_code
          }
        }
      }).then(() => this.set("isHolidayDisabled", true)).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
    static #_ = (() => dt7948.n(this.prototype, "disableHoliday", [_object.action]))();
    enableHoliday(holiday, region_code) {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      return (0, _ajax.ajax)({
        url: `/admin/discourse-calendar/holidays/enable`,
        type: "DELETE",
        data: {
          disabled_holiday: {
            holiday_name: holiday.name,
            region_code
          }
        }
      }).then(() => this.set("isHolidayDisabled", false)).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
    static #_2 = (() => dt7948.n(this.prototype, "enableHoliday", [_object.action]))();
  }, [(0, _component2.classNameBindings)("isHolidayDisabled:disabled"), (0, _component2.tagName)("tr")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminHolidaysListItem);
});